<template>
<home></home>
</template>

<script>
import {home} from "@/restaurantConfig";
    export default {
      components: {
        home
      },
      created() {
        new Promise(r => setTimeout(r, 1000)).then(()=>{
          import("../views/menu")
        })
      },
      name: "index"
    }
</script>

<style lang="sass" scoped>

</style>
